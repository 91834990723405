// ProductContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const ProductContext = createContext();

export const useProducts = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
  const [productCount, setProductCount] = useState(0);

  useEffect(() => {
    // localStorage'dan mahsulotlar sonini o'qib olamiz
    const count = JSON.parse(localStorage.getItem("productCount") || "0");
    setProductCount(count);
  }, [localStorage.getItem("productCount")]);

  const updateProductCount = (newCount) => {
    setProductCount(newCount);
    localStorage.setItem("productCount", JSON.stringify(newCount));
  };

  return (
    <ProductContext.Provider value={{ productCount, updateProductCount }}>
      {children}
    </ProductContext.Provider>
  );
};
