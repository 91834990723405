import video1 from '../videos/caruser-1.mp4'
import video2 from '../videos/caruser-2.mp4'
import video3 from '../videos/caruser-3.mp4'
import video4 from '../videos/caruser-4.mp4'
import video5 from '../videos/caruser-5.mp4'


export const utils_video = [
    {id:3, coment: video3},
    {id:4, coment: video4},
    {id:1, coment: video1},
    {id:2, coment: video2},
    
    {id:5, coment: video5},
]