import React, { useState, useEffect } from "react";
import axios from "axios";

function SaintPetersburgStreets({ setAddress }) {
  const [query, setQuery] = useState("");
  const [streets, setStreets] = useState([]);
  const [error, setError] = useState("");
  const [isView, setIsView] = useState(false);
  const apiKey = "866a74d6-c21e-4b5d-affc-9b8df04337d5";
  console.log(streets);
  useEffect(() => {
    query && setIsView(true);
    query?.length > 0 && setAddress(query);
    if (!query) return; // Agar query bo'sh bo'lsa, hech nima qilmaymiz

    const url = `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=866a74d6-c21e-4b5d-affc-9b8df04337d5&geocode=${encodeURIComponent(
      query
    )}`;

    const fetchStreets = async () => {
      try {
        const response = await axios.get(url);
        const results =
          response.data.response.GeoObjectCollection.featureMember;
        const newStreets = results.map((item) => item.GeoObject.name);
        setStreets(newStreets);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Unable to fetch data");
      }
    };

    const timerId = setTimeout(() => fetchStreets(), 500); // 500 ms kechiktirish bilan so'rov yuboramiz
    return () => clearTimeout(timerId); // Komponent yo'qolganida timer tozalanadi
  }, [query]);

  return (
    <div>
      <input
        type="text"
        value={query}
        className="text-[#15100C] h-[28px] outline-none px-2 sm:w-[335px] border rounded-md border-[#779243] font-montserrat"
        onChange={(e) => {
          setError(""); // Har bir yangi kirishda xatolarni tozalaymiz
          setQuery(e.target.value);
        }}
      />
      {error && <p>{error}</p>}
      {isView && streets && (
        <ul className="p-[5px] border-[#779243] border rounded-md max-w-[335px] mt-3">
          {streets.map((street, index) => (
            <li
              className="cursor-pointer font-montserrat text-[15px]"
              onClick={() => {
                setQuery(streets[index])
                setIsView(false)
              }}
              key={index}
            >
              {street}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SaintPetersburgStreets;
