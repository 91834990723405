import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./index.css";
import { uri, url } from "../layout/config.js";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { A11y, Navigation, Pagination } from "swiper";
import Layout from "../layout/Layout";
import toast from "react-hot-toast";
import { useProducts } from "../hooks/productContext.jsx";
import {
  useGetAllCategoryQuery,
  useGetAllFlowersQuery,
  useGetBuket2Query,
} from "../redux/api.js";
import Loader from "./Loader.jsx";
import { getAndSetCeo } from "./helpers.js";
import BuketDetail from "./BuketDetail.jsx";
import PaginationComponent from "./PaginationComponent.jsx";

function Buket4() {
  const [pageId, setPageId] = useState(1);
  const [pageSize, setPageSize] = useState(Number);
  const [flowers1, setFlowers] = useState([]);
  const { data: category = [], isLoading } = useGetAllCategoryQuery();
  const { data: flowers = [] } = useGetAllFlowersQuery(pageId);
  const [detailId, setDetailId] = useState();
  const [isDetail, setIsDetail] = useState(false);
  const [ceo, setCeo] = useState({ title: "", desc: "" });
  const [isSort, setIsSort] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const { updateProductCount } = useProducts();
  const [newSum, setNewSum] = useState([
    { id: 4, name: "Все", visible: true },
    { id: 1, name: "до 5000 руб", visible: false },
    { id: 2, name: "От 5000-10000", visible: false },
    { id: 3, name: "Свыше 10000", visible: false },
  ]);
  const [basket, setBasket] = useState(
    JSON.parse(localStorage.getItem("basket")) || []
  );
  const navigate = useNavigate();

  const basketHandler = async (id) => {
    let products = JSON.parse(localStorage.getItem("basket")) || [];
    let isProduct = products.find((c) => c.id == id);
    if (isProduct) {
      const updateProduct = products.map((item) => {
        if (item?.id == id) {
          item?.selected && navigate("/basket");
          return {
            ...item,
            count: item?.count + 1,
          };
        }
        return item;
      });
      localStorage.setItem("basket", JSON.stringify(updateProduct));
      window.scrollTo({ top: 0 });
    } else {
      // let gul = null;
      let count = JSON.parse(localStorage.getItem("productCount"));
      let newCount = count + 1;
      updateProductCount(newCount);
      flowers1?.map((item) => {
        if (item.id === id) {
          item?.selected && navigate("/basket");

          // gul = item;
          basket?.push({ ...item, count: 1, selected: false });
          localStorage.setItem("basket", JSON.stringify(basket));
        }
      });
    }

    toast.success("Добавлен в корзину");
    // navigate("/basket");
  };

  const categorySubHandler = async (id) => {
    try {
      if (id) {
        const { data } = await axios.get(
          `${url}/flowers_category_deteile/${id}/`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const subHandler = async (e, id) => {
    e.stopPropagation();
    categorySubHandler(id);
    try {
      setIsFilter(true);
      const { data } = await axios.get(`${url}/sub_category_all_views/${id}/`);
      // setSubCategory(data);
      setIsFilter(false);
    } catch (error) {
      console.log(error);
    }
  };

  const SortBySum1 = async () => {
    const { data } = await axios.get(`${url}/flowers_all_sites_views/`);
    let newArr = [];
    data?.results?.map((item) => {
      if (parseInt(item?.price) < 5000) {
        newArr.push(item);
      }
    });
    let res = newArr.sort((a, b) => parseInt(a.price) - parseInt(b.price));
    setFlowers(res);
  };

  const SortBySum2 = async () => {
    const { data } = await axios.get(`${url}/flowers_all_sites_views/`);
    let newArr = [];
    data?.results?.map((item) => {
      if (parseInt(item?.price) > 5000 && parseInt(item?.price) < 10000) {
        newArr.push(item);
      }
    });
    let res = newArr.sort((a, b) => parseInt(a.price) - parseInt(b.price));
    setFlowers(res);
  };

  const SortBySum3 = async () => {
    const { data } = await axios.get(`${url}/flowers_all_sites_views/`);
    console.log(data)
    let newArr = [];
    data?.results?.map((item) => {
      if (parseInt(item?.price) > 10000) {
        newArr.push(item);
      }
    });
    let res = newArr.sort((a, b) => parseInt(a.price) - parseInt(b.price));
    setFlowers(res);
  };

  const SortBySum4 = async () => {
    const { data } = await axios.get(`${url}/flowers_all_sites_views/`);
    setFlowers(data?.results);
  };

  const toggleCard = (item) => {
    setFlowers((prevCards) =>
      prevCards.map((card) =>
        card?.id === item?.id ? { ...card, visible: !card.visible } : card
      )
    );
  };

  const toggleCardOver = (item) => {
    setFlowers((prevCards) =>
      prevCards.map((card) =>
        card?.id === item?.id ? { ...card, visible: false } : card
      )
    );
  };

  const handleQuickDelivery = (data) => {
    navigate(`/basket/2?quick=true`);
    localStorage.setItem("quick", JSON.stringify([data]));
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    setPageSize(flowers?.count);
    setFlowers(flowers?.results);
  }, [flowers, pageId]);

  const checkBasket = () => {
    const storageProduct = JSON.parse(localStorage.getItem("basket"));

    const selectedIds = storageProduct?.map((item) => item.id);
    return flowers1?.map((item) => {
      if (selectedIds?.includes(item.id)) {
        return { ...item, selected: true };
      } else {
        return item;
      }
    });
  };
  let arr = checkBasket();

  const sortResultsByPrice = (e) => {
    e.stopPropagation();
    if (flowers1) {
      setIsSort(false);

      const res = flowers1.sort(
        (a, b) => parseInt(a?.price) - parseInt(b?.price)
      );
      console.log(res);
      setFlowers(res);
    } else {
      console.log("flowers not found");
    }
  };

  const sortByPriceDescending = (e) => {
    e.stopPropagation();
    if (flowers1) {
      setIsSort(false);
      const res = flowers1.sort(
        (a, b) => parseInt(b.price) - parseInt(a.price)
      );
      setFlowers(res);
    } else {
      console.log("flowers not found");
    }
  };

  const nextHandler = (id) => {
    setIsDetail(true);
    setDetailId(id);
  };


  return (
    <>
      <Wrapper className="">
        <Layout title={ceo.title} desc={ceo.desc}>
          <div
            className={" w-[100%] md:px-4 lg:w-[94%] mx-auto min-h-[60vh] pt-2"}
            onClick={() => {
              setIsSort(false);
              setIsFilter(false);
            }}
          >
            <div className="flex items-center mt-[20px] px-3">
              <b className="font-semibold font-montserrat w-[55%] text-[28px] md:text-[40px] text-[#15100C] text-center flex justify-end md:justify-start  md:text-start">
              Все товары
              </b>
              <p
                className="w-[45%] flex items-center justify-end gap-x-1 md:hidden"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSort((prev) => !prev);
                }}
              >
                <b className="text-[12px] font-montserrat ml-2 text-[#443926] cursor-pointer">
                  Сортировать по:
                </b>
                <div className="flex gap-x-1 ml-2">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.289062 8L1.70629 9.41L7.32496 3.83V16H9.33522V3.83L14.9438 9.42L16.3711 8L8.33009 0L0.289062 8Z"
                      fill="#443926"
                    />
                  </svg>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.4922 8L15.075 6.59L9.45631 12.17V0H7.44605V12.17L1.83744 6.58L0.410156 8L8.45118 16L16.4922 8Z"
                      fill="#443926"
                    />
                  </svg>
                </div>

                {isSort && (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="absolute right-3 text-[12px] shadow-xl text-[#443926] top-[130px] rounded-md md:rounded-xl flex flex-col gap-y-2 bg-[#EDFCD6] py-2 px-2 border border-[#443926] z-30"
                  >
                    <p
                      onClick={(e) => sortResultsByPrice(e)}
                      className="cursor-pointer"
                    >
                      ВОЗРАСТАНИЮ ЦЕНЫ
                    </p>
                    <p
                      onClick={(e) => sortByPriceDescending(e)}
                      className="cursor-pointer"
                    >
                      УБЫВАНИЮ ЦЕНЫ
                    </p>
                  </div>
                )}
              </p>
            </div>
            <div className="w-[100%] md:w-[80%] flex items-end gap-x-2 py-[10px] pt-[30px] px-4">
              <div
                className="mb-1 md:hidden"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsFilter((prev) => !prev);
                }}
              >
                <img src="/filter.svg" alt="" width={25} height={20} />
              </div>
              <div className="flex gap-x-2 md:gap-x-3 items-center relative">
                {newSum?.map((item) => (
                  <>
                    <button
                      onClick={
                        (item?.id == 1 && SortBySum1) ||
                        (item?.id == 2 && SortBySum2) ||
                        (item?.id == 3 && SortBySum3) ||
                        (item?.id == 4 && SortBySum4)
                      }
                      className={`bg-white text-[#443926] border border-[#E3E3E3] md:border-gray-400
               h-[40px] md:min-w-[180px]  md:px-8 focus:bg-[#facbd9] focus:text-black px-2 text-[12px] md:text-[20px] font-medium rounded-[14px] md:rounded-lg font-montserrat`}
                    >
                      {item?.name}
                    </button>
                  </>
                ))}
                <p
                  className="hidden md:flex items-center justify-end gap-x-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsSort((prev) => !prev);
                  }}
                >
                  <b className="text-[20px] ml-2 text-[#443926] cursor-pointer font-semibold">
                    Сортировать по:
                  </b>
                  <div className="flex gap-x-1 ml-2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      cursor={"pointer"}
                    >
                      <path
                        d="M0 8L1.41 9.41L7 3.83V16H9V3.83L14.58 9.42L16 8L8 0L0 8Z"
                        fill="#443926"
                      />
                    </svg>

                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      cursor={"pointer"}
                    >
                      <path
                        d="M16 8L14.59 6.59L9 12.17V0H7V12.17L1.42 6.58L0 8L8 16L16 8Z"
                        fill="#443926"
                      />
                    </svg>
                  </div>

                  {isSort && (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="absolute right-3 text-[16px] w-[285px] font-montserrat font-semibold shadow-xl text-[#443926] top-[50px] rounded-[5px] md:rounded-xl flex flex-col gap-y-2 bg-[#EDFCD6] py-2 px-2 border border-[#443926] z-30"
                    >
                      <p
                        onClick={(e) => sortResultsByPrice(e)}
                        className="cursor-pointer px-2"
                      >
                        ВОЗРАСТАНИЮ ЦЕНЫ
                      </p>
                      <p
                        onClick={(e) => sortByPriceDescending(e)}
                        className="cursor-pointer px-2"
                      >
                        УБЫВАНИЮ ЦЕНЫ
                      </p>
                    </div>
                  )}
                </p>
              </div>
            </div>
            {isFilter && (
              <div
                onClick={(e) => e.stopPropagation()}
                className="absolute top-[220px] left-4 p-2 md:hidden flex flex-col gap-y-1 bg-[#EDFCD6] z-[999999] border border-[#443926] rounded-lg "
              >
                {category?.map((c, idx) => (
                  <p
                    onClick={(e) => subHandler(e, c?.id)}
                    key={idx}
                    className="text-[#443926] text-[13px] cursor-pointer font-medium"
                  >
                    {c?.title}
                  </p>
                ))}
              </div>
            )}

            {/* Flowers section */}
            <div className="relative py-5 hidden md:flex px-2 flex-wrap gap-x-4 gap-y-4 mb-[40px]">
              {flowers1?.length > 0 ? (
                arr?.map((item, index) => {
                  return (
                    <div
                      onMouseLeave={() => toggleCardOver(item)}
                      onMouseEnter={() => toggleCard(item)}
                      key={index}
                      className={`${
                        item?.visible
                          ? "min-h-max left-0 sticky z-[99999] rounded-[30px]"
                          : " rounded-[30px]"
                      } z-[8]  mb-[20px] ${
                        !(index % 2 == 0)
                          ? "w-full md:w-[55%] rounded-[30px]"
                          : "w-full md:w-[43%] rounded-[30px]"
                      } relative z-0 shadow-md border rounded-[30px]`}
                    >
                      <div className=" rounded-[30px]">
                        <Swiper
                          modules={[Pagination, A11y, Navigation]}
                          navigation
                          spaceBetween={50}
                          slidesPerView={1}
                          pagination={{ clickable: true }}
                          className=""
                        >
                          {item?.flowers?.map((c, index) => (
                            <SwiperSlide
                              key={index}
                              className=" rounded-[30px]"
                            >
                              <div
                                onClick={() => nextHandler(item?.id)}
                                className={`h-[372px] sm:h-[768px] w-[100%] rounded-[30px]`}
                              >
                                <img
                                  src={`${uri}${c?.img}`}
                                  className="w-[100%] h-[100%] object-scale-down rounded-[30px] "
                                  alt={"flower"}
                                />
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>

                        <div className="p-3 sm:p-5">
                          <div>
                            <h5 className="mb-2 lg:font-medium text-[16px] text-center md:text-[24px] tracking-tight text-[#000] font-montserrat font-normal line-clamp-1">
                              {item?.name}
                            </h5>
                          </div>

                          <div className="flex justify-center md:flex-row">
                            <p className="text-[#000] font-semibold text-[24px] md:text-[32px] font-montserrat">
                              {item?.price} ₽
                            </p>
                          </div>

                          {item?.visible && (
                            <>
                              <div className="gap-x-3 font-semibold  text-[17px] md:text-[20px] font-montserrat text-[#000]">
                                {item?.rank && (
                                  <>
                                    <p className="inline mr-2">Цветы:</p>
                                    <p
                                      className="font-normal inline m-0 p-0 text-[#000] font-montserrat"
                                      dangerouslySetInnerHTML={{
                                        __html: item?.rank.replace(
                                          /<[^>]*>/g,
                                          ""
                                        ),
                                      }}
                                    />
                                  </>
                                )}
                              </div>

                              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-3 mt-6">
                                <button
                                  onClick={() => {
                                    if (item?.selected) {
                                      navigate("/basket");
                                      window.scrollTo({ top: 0 });
                                    } else {
                                      basketHandler(item.id);
                                    }
                                  }}
                                  className="bg-[#585E50] uppercase rounded-xl text-white py-[10px]  text-[18px] md:text-[20px] font-medium font-montserrat"
                                >
                                  {item?.selected
                                    ? "перейти к оформлению"
                                    : "В КОРЗИНУ"}
                                </button>
                                <button
                                  onClick={() => handleQuickDelivery(item)}
                                  className="border-2 border-[#585E50] rounded-xl text-[#585E50] py-[10px]  text-[18px] md:text-[20px] font-medium font-montserrat"
                                >
                                  БЫСТРЫЙ ЗАКАЗ
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="w-[100%] mt-[20vh] flex justify-center border-2 border-[#585E50] py-3 px-4 rounded-xl">
                  <p className="font-montserrat text-[24px] text-[#585E50]">
                    Нет таких цветов
                  </p>
                </div>
              )}
            </div>

            {/* Mobile flowers */}
            <div className="relative py-5 md:hidden flex px-2 flex-wrap gap-x-4 gap-y-4 mb-[40px]">
              {flowers1?.length > 0 ? (
                arr?.map((item, index) => {
                  return (
                    <div
                      // onMouseLeave={() => toggleCardOver(item)}
                      onMouseEnter={() => toggleCard(item)}
                      key={index}
                      className={`w-full z-[8]  mb-[20px] relative shadow-md border rounded-[30px]`}
                    >
                      <div className=" rounded-[30px]">
                        <div>
                          <Swiper
                            modules={[A11y, Navigation]}
                            navigation={true}
                            spaceBetween={50}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            className=""
                          >
                            {item?.flowers?.map((c, index) => (
                              <SwiperSlide
                                key={index}
                                className="rounded-[30px] relative"
                              >
                                {c.img && (
                                  <div className="absolute bottom-0 flex flex-col justify-end left-0 h-1/2 bgImage w-full z-40 rounded-b-[27px]">
                                    <p className="mt-[15%] font-montserrat text-[17px] text-center mb-3 font-semibold">
                                      {item?.name}
                                    </p>
                                    <p className="font-montserrat text-[17px] text-center mb-3 font-semibold">
                                      Цена: {item?.price} ₽
                                    </p>
                                    <div className="min-h-16">
                                      {item?.visible && item?.rank && (
                                        <p className="text-start font-montserrat text-[16px]">
                                          <p className="inline mr-2 font-montserrat">
                                            Цветы:
                                          </p>
                                          <p
                                            className="font-normal inline m-0 p-0 text-[#fff] font-montserrat"
                                            dangerouslySetInnerHTML={{
                                              __html: item?.rank.replace(
                                                /<[^>]*>/g,
                                                ""
                                              ),
                                            }}
                                          />
                                        </p>
                                      )}
                                    </div>
                                    <div className="mt-8 bottom-4 w-[98%] flex justify-between mb-3 items-center">
                                      <div className="w-[45%] h-[45px] bg-[#585E51] flex items-center justify-center rounded-lg">
                                        <p
                                          className="text-white text-[16px] capitalize font-montserrat font-medium"
                                          role="button"
                                          onClick={() => {
                                            if (item?.selected) {
                                              navigate("/basket");
                                              window.scrollTo({ top: 0 });
                                            } else {
                                              basketHandler(item.id);
                                            }
                                          }}
                                        >
                                          {item?.selected
                                            ? "оформлению"
                                            : "В КОРЗИНУ"}
                                        </p>
                                      </div>
                                      <div className="w-[45%] h-[45px] bg-transparent border border-white rounded-lg flex items-center justify-center">
                                        <p
                                          className="text-white text-[16px] font-montserrat font-medium"
                                          role="button"
                                          onClick={() =>
                                            handleQuickDelivery(item)
                                          }
                                        >
                                          Быстрый заказ
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <Link
                                  onClick={() => nextHandler(item?.id)}
                                  className={`h-[700px] sm:h-[620px] w-[100%] blur-load rounded-[27px]`}
                                >
                                  <img
                                    src={`${uri}${c?.img}`}
                                    className="w-[100%] h-[100%] bg-contain rounded-[30px] "
                                    alt={"flower"}
                                  />
                                </Link>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="w-[95%] mx-auto md:mx-0 flex mt-[20%] justify-center border-2 border-[#585E50] py-3 px-4 rounded-xl">
                  <p className="font-montserrat text-[24px] text-[#585E50]">
                    Нет таких цветов
                  </p>
                </div>
              )}
            </div>

            <div className="mt-8 md:m-24 pb-24 flex w-full justify-center ">
              <PaginationComponent pageSize={pageSize} setPageId={setPageId} />
            </div>
          </div>
        </Layout>
      </Wrapper>
      {isDetail && (
        <Wrapper
          className={`${
            !isDetail && "hidden"
          } fixed top-0 left-0 overflow-y-auto w-full h-screen z-[9999999999]`}
        >
          <BuketDetail
            id={detailId}
            setIsDetail={setIsDetail}
            isDetail={isDetail}
          />
        </Wrapper>
      )}
    </>
  );
}

export default Buket4;

const Wrapper = styled.section`
  .card {
    background-color: #edfcd6;
    padding: 48px;
    border-radius: 30px;

    .card-list {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(490px, 1fr));
      justify-items: center;
      gap: 32px;

      .card-item {
        width: 100%;
        border: 2px solid ${(props) => props.theme.colors.whiteColor};

        img {
          // width: 100%;
          // height: 395px;
          object-fit: cover;
        }

        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 39px;
          color: ${(props) => props.theme.colors.whiteColor};
          text-align: center;
          padding: 32px 0 83px;
        }
      }
    }
  }
`;
