import React, { useEffect, useState, memo } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./index.css";
import { uri, url } from "../layout/config.js";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { A11y, Pagination } from "swiper";
import toast from "react-hot-toast";
import { useProducts } from "../hooks/productContext.jsx";
import { useGetBuketQuery } from "../redux/api.js";
import Loader from "./Loader.jsx";

const Buket = memo(function Buket({}) {
  // const [data, setFlowers] = useState([]);
  const [basket, setBasket] = useState(
    JSON.parse(localStorage.getItem("basket")) || []
  );
  const { data = [], isLoading } = useGetBuketQuery();
  const { updateProductCount } = useProducts();

  const navigate = useNavigate();
  const location = useLocation();

  const basketHandler = async (id) => {
    let products = JSON.parse(localStorage.getItem("basket")) || [];
    let isProduct = products.find((c) => c.id == id);
    if (isProduct) {
      const updateProduct = products.map((item) => {
        if (item?.id == id) {
          return {
            ...item,
            count: item?.count + 1,
          };
        }
        return item;
      });
      localStorage.setItem("basket", JSON.stringify(updateProduct));
      navigate("/basket")
      window.scrollTo({top: 0})
    } else {
      let count = JSON.parse(localStorage.getItem("productCount"));
      let newCount = count + 1;
      updateProductCount(newCount);
      data?.results?.map((item) => {
        if (item.id === id) {
          // gul = item;
          basket?.push({ ...item, count: 1, selected: false });
          localStorage.setItem("basket", JSON.stringify(basket));
        }
      });
      navigate("/basket")
      window.scrollTo({top: 0})
    }

    toast.success("Добавлен в корзину");
  };

  if (isLoading) return <Loader />;
  return (
    <Wrapper>
      <>
        <div className="w-[90%] lg:w-[94%] mx-auto">
          <p className="text-[48px] mb-12 font-semibold leading-[58px] text-[#15100C] flex justify-center md:justify-start">
            Букеты
          </p>
          <div
            className="rounded-lg relative p-5 flex flex-wrap gap-x-4 gap-y-4 mb-[40px] justify-center"
            style={{ background: "rgba(44, 81, 70, 0.48)" }}
          >
            {data?.results?.length > 0 ? (
              data?.results?.map((item, index) => {
                return (
                  location.pathname == "/" &&
                  index < 4 && (
                    <div
                      key={index}
                      className={` z-[8]  mb-[20px] w-full md:w-[49%] sticky card-shadow border rounded-lg`}
                    >
                      <div className="bg-blue-350 rounded-lg">
                        <div>
                          <Swiper
                            modules={[Pagination, A11y]}
                            spaceBetween={50}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                          >
                            {item?.flowers?.map((c, index) => (
                              <SwiperSlide key={index}>
                                <div
                                  role="button"
                                  onClick={() => {
                                    navigate(`/bouquets/${item.id}`);
                                    window.scrollTo({
                                      top: 0,
                                    });
                                  }}
                                  key={index}
                                  className={`rounded-t-lg relative h-[273px] blur-div sm:h-[440px] w-[100%] blur-load ${
                                    ((uri + c?.img).length < 0 ||
                                      c?.img?.length < 0) &&
                                    "blurimage"
                                  }`}
                                >
                                  <img
                                    loading="lazy"
                                    src={` ${
                                      c?.img?.includes(
                                        "https://buketyana-admin.ru"
                                      )
                                        ? c?.img
                                        : uri + c?.img
                                    } `}
                                    className="w-[100%] h-[100%]  object-cover rounded-t-lg"
                                    alt={"flower"}
                                  />
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                        <div className="p-3 sm:p-5">
                          <div>
                            <h5 className="lg:font-semibold xl:font-bold text-[16px] md:text-[18px] text-[#fff] font-normal line-clamp-1">
                              {item?.name}
                            </h5>
                          </div>

                          <div className="flex flex-row justify-between items-center gap-x-3 gap-y-3 mt-2">
                            <button
                              onClick={() => basketHandler(item.id)}
                              className="bg-[#585E50] px-4 rounded-xl text-white py-[10px]  text-[18px] md:text-[20px] font-medium font-montserrat"
                            >
                              В КОРЗИНУ
                            </button>
                            <div className="flex justify-center md:flex-row">
                              <p className="text-[#fff] font-normal text-[18px] md:text-[24px] font-montserrat">
                                {item?.price} ₽
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })
            ) : (
              <div className="w-[100%] flex justify-center">
                <p className="font-montserrat text-[24px] text-white">
                  Нет таких цветов
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    </Wrapper>
  );
});

export default Buket;

const Wrapper = styled.section`
  .card {
    background-color: ${(props) => props.theme.colors.cardBackground};
    padding: 48px;
    border-radius: 30px;

    .card-list {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(490px, 1fr));
      justify-items: center;
      gap: 32px;

      .card-item {
        width: 100%;
        border: 2px solid ${(props) => props.theme.colors.whiteColor};

        img {
          // width: 100%;
          // height: 395px;
          object-fit: cover;
        }

        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 39px;
          color: ${(props) => props.theme.colors.whiteColor};
          text-align: center;
          padding: 32px 0 83px;
        }
      }
    }
  }
`;
