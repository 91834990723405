import React, { useEffect, useState, memo } from "react";
import { uri, url } from "../layout/config";
import axios from "axios";
import { Link } from "react-router-dom";
import { useGetAllCategoryQuery } from "../redux/api";
import Loader from "../components/Loader";

const HomeCategory = () => {
  const [isCategory, setIsCategory] = useState(false);
  const { data = [], isLoading } = useGetAllCategoryQuery();

  let widthCard = [
    { left: 30, right: 68 },
    { left: 58, right: 40 },
    { left: 38, right: 60 },
  ];
  const shuffleBg = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    widthCard = array;
    return array;
  };
  useEffect(() => {
    shuffleBg(widthCard);
  }, []);
  if (isLoading) return <Loader />;
  return (
    <div className="flex flex-col mb-4 p-1 sm:p-4 md:w-[96%] mx-auto mt-[40px]">
      <div className="relative mb-4 flex flex-wrap justify-between flex-col">
        <div className="flex items-center justify-between gap-x-2">
          {data
            // .sort((a, b) => a.id - b.id)
            .slice(0, 2)
            ?.map((item, index) => (
              <Link
                to={`/bouquets/category/${item?.id}`}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                  });
                }}
                key={item?.id}
                className={`w-[100%] ${
                  index % 2 == 0 ? "w-[43%]" : "w-[56%]"
                } relative mb-4`}
              >
                <div className="w-[100%] h-[300px] md:h-[500px]">
                  <img
                    src={`${uri}${item?.img}`}
                    className="w-[100%] h-[100%] object-cover"
                  />
                </div>
                <div className="absolute top-0 left-0 bg-modal3 w-[100%]">
                  <p
                    style={{
                      textTransform: "capitalize",
                      letterSpacing: "5px",
                    }}
                    className="py-3 text-[18px] font-medium capitalize sm:text-[24px] md:text-[34px] md:font-semibold text-white text-center"
                  >
                    {item?.title.toLowerCase()}
                  </p>
                </div>
              </Link>
            ))}
        </div>
        <div className="flex items-center justify-between gap-x-2">
          {data
            // .sort((a, b) => a.id - b.id)
            .slice(2, 4)
            ?.map((item, index) => (
              <Link
                onMouseEnter={() => console.log(item.id)}
                to={`/bouquets/category/${item?.id}`}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                  });
                }}
                key={item?.id}
                className={`w-[100%] ${
                  index % 2 == 0 ? "w-[56%]" : "w-[43%]"
                } relative mb-4`}
              >
                <div className="w-[100%] h-[300px] md:h-[500px]">
                  <img
                    src={`${uri}${item?.img}`}
                    className="w-[100%] h-[100%] object-cover"
                  />
                </div>
                <div className="absolute top-0 left-0 bg-modal3 w-[100%]">
                  <p
                    style={{
                      textTransform: "capitalize",
                      letterSpacing: "5px",
                    }}
                    className="py-3 text-[18px] font-medium capitalize sm:text-[24px] md:text-[34px] md:font-semibold text-white text-center"
                  >
                    {item?.title.toLowerCase()}
                  </p>
                </div>
              </Link>
            ))}
        </div>
        {isCategory && (
          <>
            <div className="flex items-center justify-between gap-x-2">
              {data
                // .sort((a, b) => a.id - b.id)
                .slice(4, 6)
                ?.map((item, index) => (
                  <Link
                    onMouseEnter={() => console.log(item.id)}
                    // to={
                    //   (item?.id == "48" && `/bouquets/categories/49`) ||
                    //   (item?.id == "49" && `/bouquets/categories/50`)
                    // }
                    to={`/bouquets/category/${item?.id}`}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                      });
                    }}
                    key={item?.id}
                    className={`w-[100%] ${
                      index % 2 == 0 ? "w-[43%]" : "w-[56%]"
                    } relative mb-4`}
                  >
                    <div className="w-[100%] h-[300px] md:h-[500px]">
                      <img
                        src={`${uri}${item?.img}`}
                        className="w-[100%] h-[100%] object-cover"
                      />
                    </div>
                    <div className="absolute top-0 left-0 bg-modal3 w-[100%]">
                      <p
                        style={{
                          textTransform: "capitalize",
                          letterSpacing: "5px",
                        }}
                        className="py-3 text-[18px] font-medium capitalize sm:text-[24px] md:text-[34px] md:font-semibold text-white text-center"
                      >
                        {item?.title.toLowerCase()}
                      </p>
                    </div>
                  </Link>
                ))}
            </div>
            <div className="flex items-center justify-between gap-x-2">
              {data
                // .sort((a, b) => a.id - b.id)
                .slice(6, 8)
                ?.map((item, index) => (
                  <Link
                    onMouseEnter={() => console.log(item.id)}
                    to={`/bouquets/category/${item?.id}`}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                      });
                    }}
                    key={item?.id}
                    className={`w-[100%] ${
                      index % 2 == 0 ? "w-[56%]" : "w-[43%]"
                    } relative mb-4`}
                  >
                    <div className="w-[100%] h-[300px] md:h-[500px]">
                      <img
                        src={`${uri}${item?.img}`}
                        className="w-[100%] h-[100%] object-cover"
                      />
                    </div>
                    <div className="absolute top-0 left-0 bg-modal3 w-[100%]">
                      <p
                        style={{
                          textTransform: "capitalize",
                          letterSpacing: "5px",
                        }}
                        className="py-3 text-[18px] font-medium capitalize sm:text-[24px] md:text-[34px] md:font-semibold text-white text-center"
                      >
                        {item?.title.toLowerCase()}
                      </p>
                    </div>
                  </Link>
                ))}
            </div>
          </>
        )}
      </div>
      {!isCategory && (
        <div className="w-[100%] flex justify-center">
          <button
            onClick={() => setIsCategory(true)}
            className="py-[20px] px-[60px] text-[12px] lg:text-[20px] font-montserrat rounded-lg text-[#fff] bg-[#C59A77]"
          >
            Смотреть все разделы
          </button>
        </div>
      )}
    </div>
  );
};

export default HomeCategory;
