import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ProductProvider } from "./hooks/productContext";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ScrollProvider } from "./components/ScrollContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollProvider>
        <ProductProvider>
          <App />
        </ProductProvider>
      </ScrollProvider>
    </BrowserRouter>
  </Provider>
);
