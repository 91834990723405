import { Pagination } from "@mui/material";
import React from "react";

const PaginationComponent = ({ pageSize, setPageId }) => {
  return (
    <Pagination
      sx={{ color: "white" }}
      count={
        pageSize % 10 != 0
          ? Math.floor(pageSize / 10) + 1
          : Math.floor(pageSize / 10)
      }
      onChange={(e, value) => {
        setPageId(value)
        window.scrollTo({top: 0})
      }}
      variant="outlined"
      shape="rounded"
      color="secondary"
    />
  );
};

export default PaginationComponent;
