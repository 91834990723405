import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const buketApi = createApi({
  reducerPath: "goodsApi",
  tagTypes: ["Products"],
  baseQuery: fetchBaseQuery({ baseUrl: "https://buketyana-admin.ru/api/" }),
  endpoints: (build) => ({
    getSubCategory: build.query({
      // query: (limit = "") => `goods?${limit && `_limit=${limit}`}`,
      query: () => `base/sub_categoriya_base_all_views/`,
    }),
    getAskiya: build.query({
      // query: (limit = "") => `goods?${limit && `_limit=${limit}`}`,
      query: () => `base/askiya_all/`,
    }),
    getAllCategory: build.query({
      query: () => `sayts/category_all_views/`,
    }),
    getCommitVideo: build.query({
      query: () => `sayts/commit_vidoes_sites_views/`,
    }),
    getAllBlog: build.query({
      query: () => `sayts/blogs_all_sites_views/?page=1`,
    }),
    getBuket: build.query({
      query:() =>  `sayts/flowers_all_sites_views/`
    }),
    getBuket2: build.query({
      query: (id = "") => `sayts/flowers_sub_category_deteile/${id}/`
    }),
    getBuket3: build.query({
      query: (id = "") => `sayts/flowers_category_deteile/${id}`
    }),
    getMainCategory: build.query({
      query: () => `base/categoriya_base_all_views/`,
    }),
    getMainSubCategory: build.query({
      query: (id = "") => `base/categoriya_deteile/${id}/`
    }),
    getAllFlowers: build.query({
      query: (page = "") => `sayts/flowers_all_sites_views/?page=${page}`,
    })
  }),
});

export const {
  useGetSubCategoryQuery,
  useGetAskiyaQuery,
  useGetAllCategoryQuery,
  useGetCommitVideoQuery,
  useGetAllBlogQuery,
  useGetBuketQuery,
  useGetBuket2Query,
  useGetBuket3Query,
  useGetMainCategoryQuery,
  useGetMainSubCategoryQuery,
  useGetAllFlowersQuery
} = buketApi;
