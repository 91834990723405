import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useGetAskiyaQuery } from "../redux/api";
import Loader from "./Loader";

const NewHeros = ({ img, svg, img2, heroTitle, cardTitle, price, link }) => {
  const navigate = useNavigate();
  const { data = [], isLoading } = useGetAskiyaQuery()

  
  if (isLoading) return <Loader />
  return (
    <div className="relative w-full md:w-[94%] mx-auto my-0 py-0">
      <div className="w-full h-[441px] lg:h-[580px] xl:h-[600px] hidden md:flex items-center justify-center">
        <img src={img} alt="NewHero" className="w-full h-full" />
      </div>
      <div className="w-[100%] h-[460px] md:hidden">
        <img src={img2} alt="NewHero" className="w-full h-full" />
      </div>

      {/* laptop */}
      <div className="absolute left-[30%] top-[15%] hidden md:flex w-[40%] text-start">
        {/* <p className="text-white lg:text-[40px] font-bold font-montserrat">
          {heroTitle}
        </p> */}
      </div>
      {/* mobile */}
      {data[0]?.is_active && (
        <div
          onClick={() => navigate(link)}
          className="absolute md:top-[10%] top-[5%] rounded-xl md:hidden left-[3%] flex px-4 md:p-0  w-[94%] md:w-[35%] max-h-[200px] bg-modal2 md:rounded-xl border"
        >
          <p className="text-white text-[15px] sm:text-lg font-montserrat font-medium text-center w-full py-1 leading-8">
            На первый заказ с сайта скидка на <br /> любой букет 15% +
            бесплатная доставка <br /> по Санкт-Петербургу
          </p>
        </div>
      )}
      {false && (
        <div className="absolute md:top-[10%] md:hidden top-0 mb-4 left-0 md:right-[6%] p-4 md:p-0 w-[100%] md:w-[40%] h-full md:h-[400px] bg-modal2 md:rounded-xl border">
          <div className="absolute top-10 w-[85%] mb-8 z-30">
            {/* <div className="md:hidden flex text-start">
            <p className="text-white text-[15px] font-bold line-clamp-4 font-montserrat">
              {heroTitle}
            </p>
          </div> */}
            {/* <div className="w-[100%] flex flex-row justify-between items-center">
            <div className="md:flex hidden w-[18%] h-[385px] items-center justify-center">
              <div className="md:w-[66px]  md:h-[66px]">
                <img
                  src={svg}
                  alt={"NewHero"}
                  width={"100%"}
                  height={"100%"}
                  className="bg-contain object-contain"
                />
              </div>
            </div>
            <div className="w-[95%] md:w-[80%]">
              <p className="font-medium mt-4 text-sm text-start font-montserrat text-white">
                {cardTitle}
              </p>
              <p onClick={() => navigate(link)} className="text-white text-[20px] md:text-[28px] font-montserrat py-1 lg:text-[36px] text-start line-clamp-1">
                от {price} ₽
              </p>
              
              <div onClick={(e) => {
                e.stopPropagation()
                navigate(link)
              }} className="flex items-center z-50 w-[80%] gap-x-3">
                <p onClick={() => navigate(link)} className="text-white z-10 mr-1 sm:mr-8 font-medium md:font-semibold text-[14px] text-start md:text-[19px] lg:text-[24px] font-montserrat line-clamp-4">
                  Смотреть все букеты
                </p>
                <svg
                  className="sm:w-[59px] w-[40px]"
                  height={16}
                  viewBox="0 0 59 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M58.7071 8.70711C59.0976 8.31659 59.0976 7.68342 58.7071 7.2929L52.3431 0.928937C51.9526 0.538412 51.3195 0.538412 50.9289 0.928937C50.5384 1.31946 50.5384 1.95263 50.9289 2.34315L56.5858 8L50.9289 13.6569C50.5384 14.0474 50.5384 14.6805 50.9289 15.0711C51.3195 15.4616 51.9526 15.4616 52.3431 15.0711L58.7071 8.70711ZM-8.74228e-08 9L58 9.00001L58 7.00001L8.74228e-08 7L-8.74228e-08 9Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      )}
      {data[0]?.is_active && (
        <div
          onClick={() => navigate(link)}
          className="absolute md:top-[10%] md:flex hidden md:left-[1%] px-4 md:p-0 w-[100%] md:w-[35%] h-full md:h-auto bg-modal2 md:rounded-xl border"
        >
          <p className="text-white text-2xl font-montserrat font-semibold px-4 py-2 leading-9 text-center w-full">
            На первый заказ с сайта скидка на <br /> любой букет 15% +
            бесплатная доставка <br /> по Санкт-Петербургу
          </p>
        </div>
      )}
    </div>
  );
};

export default NewHeros;

let a = `{
  /* <div className="absolute bottom-4 right-4 flex md:hidden items-center justify-center">
          <div className="md:w-[66px]  md:h-[66px] flex items-center justify-center">
            <img src={svg} alt={"NewHero"} width={"100%"} height={"100%"} />
          </div>
        </div>
        <div className="md:hidden flex text-start">
          <p className="text-white lg:text-[40px] font-montserrat font-bold line-clamp-4">
            {heroTitle}
          </p>
        </div>
        <div className="w-[100%] flex flex-row justify-between items-center px-2">
          <div className="w-[95%] md:w-[80%] mx-[8%] mr-4">
            <p className="font-medium md:font-semibold text-[14px]  mt-4 text-start md:text-[17px] lg:text-[20px] font-montserrat text-white">
              {cardTitle}
            </p>
            <p className="text-white text-[24px] md:text-[28px] py-1 lg:text-[36px] text-start line-clamp-1">
              от {price} ₽
            </p>
            <div onClick={() => navigate(link)} className="flex items-center mb-3 cursor-pointer">
              <p className="text-white mr-3 sm:mr-8 font-medium md:font-semibold text-[14px]  text-start md:text-[19px] lg:text-[20px] font-montserrat">
                Смотреть все букеты
              </p>
              <svg
                width={59}
                height={16}
                viewBox="0 0 59 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M58.7071 8.70711C59.0976 8.31659 59.0976 7.68342 58.7071 7.2929L52.3431 0.928937C51.9526 0.538412 51.3195 0.538412 50.9289 0.928937C50.5384 1.31946 50.5384 1.95263 50.9289 2.34315L56.5858 8L50.9289 13.6569C50.5384 14.0474 50.5384 14.6805 50.9289 15.0711C51.3195 15.4616 51.9526 15.4616 52.3431 15.0711L58.7071 8.70711ZM-8.74228e-08 9L58 9.00001L58 7.00001L8.74228e-08 7L-8.74228e-08 9Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div> */
};`;
