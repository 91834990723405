import { Route, Routes } from "react-router-dom";
import GlobalStyle from "./components/GlobalStyle";
import Theme from "./theme/Theme";
import { Toaster } from "react-hot-toast";
import { routes } from "./route.js";
import Sitemap from "./sitemap1.js";
import ErrorPage from "./components/ErrorPage.jsx";
import { ProductsContext } from "./context/ProductContext.js";
import { ProductProvider, useProducts } from "./hooks/productContext.jsx";
import { useEffect } from "react";

function App() {
  const { productCount, updateProductCount } = useProducts();

  useEffect(() => {
    let a = localStorage.getItem("basket");
    if (a) {
      updateProductCount(JSON.parse(a)?.length);
    }
  }, []);
  return (
    <Theme>
      <GlobalStyle />

      <Routes>
        {routes?.map((c) => (
          <Route
            key={c?.id}
            path={`${c?.path}`}
            title={c?.title}
            element={c?.element}
            logo={c?.logo}
            kor={c?.kor}
          />
        ))}
        <Route path="/sitemap.xml" element={<Sitemap />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Toaster toastOptions={{ duration: 3000 }} />
    </Theme>
  );
}

export default App;
