import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { BiMessageDetail } from "react-icons/bi";
import { IoIosArrowDropupCircle } from "react-icons/io";

import { Link } from "react-router-dom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { UseScrolled } from "../hooks/useScrolled";

const Layout = ({ children, title, logo, kor, desc, navbar }) => {
  const [len, setLen] = useState(0);
  const scrolled = UseScrolled();
  const [isMessage, setIsMessage] = useState(false);
  const toTop = document.querySelector(".to-top");
  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 100) {
      toTop?.classList.add("active");
    } else {
      toTop?.classList.remove("active");
    }
  });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={desc} />
      </Helmet>
      <header className={`sm:mb-0`}>
        <Header logo={logo} lay={kor} navbar={navbar} />
      </header>
      <main>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { len, setLen })
        )}
      </main>
      {/* Phone */}
      <Link
        to={"tel: +79119276162"}
        className="bg-slate-800 h-[50px] md:h-[50px] w-[50px] md:w-[50px] rounded-full fixed flex items-center justify-center z-[99999] right-2 top-[76%] md:top-[83%] cursor-pointer"
      >
        <LocalPhoneIcon fontSize="medium" sx={{ color: "#fff" }} />
      </Link>
      {/* Message */}
      <div
        className="bg-slate-800 h-[50px] md:h-[50px] w-[50px] md:w-[50px] rounded-full fixed flex items-center justify-center z-[99999] left-2 top-[76%] md:top-[83%] cursor-pointer"
        onClick={() => setIsMessage((prev) => !prev)}
      >
        <img src="/images/mes.svg" alt="" className="p-3" />
        {isMessage && (
          <div
            className="relative flex flex-col"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col  absolute top-[-55px] gap-y-6 md:gap-y-8 left-4">
              <Link
                to={`https://api.whatsapp.com/send?phone=79119276162`}
                className="bg-[#41A96AF5] w-[230px] md:w-[250px] flex justify-between items-center px-2 py-0 md:py-0 rounded-md "
              >
                <p className="text-white tracking-[1px] font-montserrat text-[14px] md:text-[15px]">
                  Написать в Вотсап
                </p>
                <div className="w-[40px] h-[40px]">
                  <img
                    src={"/images/t2.svg"}
                    alt="message"
                    width={"100%"}
                    height={"100%"}
                    className="p-2"
                  />
                </div>
              </Link>
              <Link
                to={`https://t.me/+79119276162`}
                className="bg-[#7DAAE5] w-[230px] md:w-[250px] flex justify-between items-center px-2  py-0 md:py-0 rounded-md "
              >
                <p className="text-white tracking-[1px] font-montserrat text-[14px] md:text-[15px]">
                  Написать в Телеграм
                </p>
                <div className="w-[40px] h-[40px]">
                  <img
                    src={"/images/t1.svg"}
                    alt="message"
                    width={"100%"}
                    height={"100%"}
                    className="p-2"
                  />
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>

      {/* to top */}
      {scrolled && (
        <div
          onClick={() => window.scrollTo({ top: 0 })}
          className="bg-slate-800 h-[50px] md:h-[50px] w-[50px] md:w-[50px] rounded-full fixed flex items-center justify-center z-[99999] right-2 top-[86%] md:top-[93%] cursor-pointer"
        >
          <IoIosArrowDropupCircle fontSize={"28px"} color="white" />
        </div>
      )}

      <Footer navbar={navbar} />
    </>
  );
};

Layout.defaultProps = {
  title: "Букетная-мануфактура",
};

export default Layout;
