export const getAndSetCeo = (id, setCeo) => {
  switch (id) {
    case "46":
      setCeo({
        title: "Осень",
        desc: "Дарите тепло с осенними цветами! Наша коллекция осенних букетов и композиций позволит вам насладиться теплыми оттенками и уютной атмосферой. У нас есть идеальный букет для создания осеннего настроения.",
      });
      break;
    case "47":
      setCeo({
        title: "Зима",
        desc: "Отправьте волшебство зимы вместе с нашими зимними композициями. Снежные белые розы, голубые эустомы, серебристые декорации - наши букеты передадут белоснежную красоту зимы и создадут атмосферу праздника и волшебства. ",
      });
      break;
    case "48":
      setCeo({
        title: "Весна",
        desc: "Добро пожаловать в цветущий мир весны! От ярких тюльпанов до нежных пионов и ароматных фрезий, наши весенние букеты наполнят ваш дом свежестью и радостью. Пусть каждый весенний день будет полон цветов и веселья!",
      });
      break;
    case "45":
      setCeo({
        title: "Лето",
        desc: "Яркие цветы и солнечное настроение - вот что предлагает наша коллекция летних букетов. От свежих полей лаванды до ярких подсолнухов, наши букеты принесут радость и веселье в самые жаркие дни лета.",
      });
      break;
    case "52":
      setCeo({
        title: "Вазы",
        desc: "Украшайте свои цветы в наших стильных и элегантных вазах. Выбирайте из различных форм и дизайнов, чтобы создать уникальную и привлекательную композицию. Наши вазы станут стильным аксессуаром для вашего интерьера и подчеркнут красоту и изысканность цветов.",
      });
      break;
    case "49":
      setCeo({
        title: "Сухоцветы",
        desc: "Изысканные и богатые текстурой, сухоцветы создают особенную атмосферу. Наши сухоцветы подчеркнут настроение и стиль вашего интерьера, привнесут уют и природную красоту.",
      });
      break;
    case "53":
      setCeo({
        title: "Открытки",
        desc: "Выразите свои чувства с помощью наших уникальных открыток. Они станут идеальным дополнением к вашему цветочному подарку и позволят передать теплые слова и пожелания.",
      });
      break;
    case "54":
      setCeo({
        title: "Свечи",
        desc: "Создайте мягкий и романтический свет с нашей коллекцией свечей. Они добавят атмосферу уюта и спокойствия, а также подчеркнут красоту и аромат цветов.",
      });
      break;
    case "55":
      setCeo({
        title: "Сертификаты",
        desc: "Подарите возможность выбора с нашими сертификатами. Они станут идеальным подарком для тех, кто ценит красоту цветов и хочет самостоятельно выбрать букет или композицию.",
      });
      break;
    case "50":
      setCeo({
        title: "Авторские букеты",
        desc: "Добро пожаловать в мир уникальных авторских букетов! Наши флористы создают яркие и оригинальные композиции из изысканных цветов. Каждый букет - это произведение искусства, призванное порадовать и удивить своего владельца.",
      });
      break;
    case "51":
      setCeo({
        title: "Монобукеты",
        desc: "Простота и элегантность в каждом букете. Монобукеты - это идеальный способ подчеркнуть красоту и нежность каждого цветка. Они станут великолепным подарком для любого случая и позволят цветам выразить свою прелесть в полной мере.",
      });
      break;
  }
};
